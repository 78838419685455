* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.login img {
  height: 32px;
  margin-bottom: 24px;
}

.login form {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.login label {
  margin-bottom: 4px;
  margin-top: 8px;
}

.login button {
  margin-top: 16px;
}

input {
  font-size: 14px;
  padding: 2px 4px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #d4d4d4;
}

input:focus {
  border: solid 1px #3689e6;
  outline: none;
}

select {
  font-size: 14px;
  padding: 2px 4px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 2px;
  border: solid 1px #d4d4d4;
}

select:focus {
  border: solid 1px #3689e6;
  outline: none;
}

header {
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #fafafa99;
  backdrop-filter: blur(8px);
  border-bottom: solid 1px #d4d4d4;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 4px 16px;
}

footer {
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: #fafafa99;
  backdrop-filter: blur(8px);
  width: 100%;
  border-top: solid 1px #d4d4d4;
  height: 20px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: #555761;
}

section {
  padding-top: 32px;
  padding-bottom: 32px;
}

header img {
  height: 20px;
  /* border: solid 1px; */
  margin-bottom: -4px;
}

header a {
  padding: 8px;
  color: #555761;
}

a.active {
  color: #3689e6;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s;
  margin: 2px;
}

a:hover {
  transition: 0.2s;
  color: #333333;
  /* text-shadow: 0px 1px 2px #abacae; */
}

a.active:hover {
  color: #3689e6;
}

table {
  width: 100%;
  border-collapse: collapse;
}

tbody tr {
  border-bottom: solid 1px #d4d4d4;
  transition: 0.2s;
}

tbody tr:hover {
  background-color: #d4d4d433;
  transition: 0.2s;
}

thead tr {
  border-bottom: solid 1px #abacae;
}

th {
  text-align: left;
  padding: 4px;
  vertical-align: bottom;
  color: #555761;
}

td {
  padding: 4px !important;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100vh - 48px);
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal form label {
  margin-bottom: 8px;
}

.modal form input,
.modal form select {
  margin-bottom: 16px;
  min-width: 250px;
}

.modal form input[type="checkbox"] {
  max-width: 16px !important;
  min-width: 16px !important;
  width: 16px !important;
  height: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.modal .special {
  width: 60px !important;
  min-width: 60px;
}

.modal form div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.details-section-one {
  display: flex;
  flex-direction: column;
}

.details-section-one form {
  margin: 16px;
}

.details-form div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.details-form label {
  width: 150px;
}

.details-form input,
.details-form select {
  width: 250px;
}

.table {
  margin: 16px;
  margin-bottom: 32px;
}

.myform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  width: 300px;
  margin-top: 16px;
}

.myform div {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.myform label,
.myform input {
  width: 200px;
}

.category-select {
  width: 154px;
}

.btn {
  border-radius: 2px;
  border: solid 1px;
  height: 24px;
  color: #fafafa;
  padding: 2px 4px;
  margin: 2px;
  transition: 0.2s;
  font-size: 14px;
  cursor: pointer;
  text-shadow: 0px 1px 2px #abacae;
}

.btn.lg {
  font-size: 16px;
  height: 28px;
  padding: 4px 8px;
}

.btn:hover {
  color: #ffffff;
  transition: 0.2s;
}

.btn:focus {
  outline: none;
}

.btn.blue {
  background-color: #3689e6;
  border-color: #0d52bf;
}

.btn.blue:hover {
  background-color: #64baff;
  border-color: #3689e6;
}

.btn.red {
  background-color: #c6262e;
  border-color: #a10705;
}

.btn.red:hover {
  background-color: #ed5353;
  border-color: #c6262e;
}

.btn.green {
  background-color: #28bca3;
  border-color: #0e9a83;
}

.btn.green:hover {
  background-color: #43d6b5;
  border-color: #28bca3;
}

.btn.gray {
  background-color: #abacae;
  border-color: #7e8087;
}

.btn.gray:hover {
  background-color: #d4d4d4;
  border-color: #abacae;
}

td a {
  display: inline-flex;
}

td .btn {
  margin-right: -2px;
  margin-left: 8px;
}

caption {
  /* text-align: left; */
  padding: 8px 0;
  /* font-size: 16px; */
  /* background-color: #0d52bf; */
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d499;
  backdrop-filter: blur(8px);
}

.center {
  text-align: center;
}

.interested-container {
  display: flex;
}

.list-item-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  transition: 0.2s;
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: solid 1px #d4d4d4;
}

.list-item-active {
  background-color: #9ebced;
}

.arrow {
  margin-left: 16px;
  font-size: large;
}

.students {
  /* border: solid 1px #d4d4d4; */
  flex-grow: 1;
  margin: 16px;
}
